<template>
  <div>
    <div class="col-12 d-flex">
      <div class="col-6">
        <v-card class="pl-3 pr-3">
          <v-card-subtitle>
            <span class="blue--text text-h6"> Temel Bilgileri </span>
          </v-card-subtitle>
          <v-divider />

          <div
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="d-flex">
              <div class="col-3 text-left font-weight-bold">
                <span v-if="item"> {{ item.title }} </span>
              </div>
              <div class="col-6 text-left">
                : <span v-if="item"> {{ item.value }} </span>
              </div>
            </div>
            <v-divider />
          </div>
        </v-card>
      </div>

      <div class="col-6">
        <v-card
          min-height="300"
          class=""
        >
          <v-card-subtitle dense>
            <span class="blue--text text-h6"> Logo </span>
          </v-card-subtitle>
          <v-divider />
          <v-card-text class="mt-2 mb-1 ml-4 d-flex">
            <v-col class="col-6">
              <v-img
                :src="ImageUrl"
                width="400"
              />
            </v-col>

            <v-col class="col-6 text-right">
              <v-spacer />
              <v-btn @click="logoModal = true">
                Logo Ekle
              </v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </div>

      <v-dialog
        v-model="logoModal"
        width="500"
      >
        <v-card>
          <v-card-text>
            <v-file-input
              v-model="files"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Şİrket Logo Ekle"
              label="Logo"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="logoModal = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="uploadImage"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { getCompany, getAdminCompany, setAdminCompanyLogo } from '@/api/Request/company';
import { getUserList } from '@/api/Request/user';
import { getCompanyFarmList } from '@/api/Request/farm';
import dev from '@/dev';

export default {
  data: () => ({
    companyCode: null,
    userCount: 0,
    logoModal: false,
    companyData: {},
    items: [],
    totalNominalPlantPower: {
      value: 0,
      unit: '',
    },
    files: '',
    ImageUrl: '',
  }),
  computed: {
  },
  watch: {},
  created() { },
  mounted() {
    this.selectedFarmFunc();
  },
  methods: {

    uploadImage() {
      const formData = new FormData();
      formData.append('image', this.files);
      setAdminCompanyLogo(formData).then((res) => {
        console.log(res);
      });
    },

    selectedFarmFunc() {
      const storage = window.localStorage;
      const userCompany = JSON.parse(window.atob(storage.getItem('user_company')));
      this.companyCode = userCompany.companyCode;
      this.prefix = userCompany.prefix;

      this.getCompany();
    },

    getCompany() {
      const params = {};
      this.companyData = [];
      getAdminCompany(params).then((res) => {
        this.companyData = res.data.data;

        this.ImageUrl = `${dev.baseUrl}/logo/${this.companyData.logo}`;

        this.items = [
          {
            title: ' Şirket Adı',
            value: res.data.data.adminCompanyName,
          },

          {
            title: 'Oluşturma Tarih',
            value: res.data.data.createTime,
          },
          {
            title: 'Email',
            value: res.data.data.email,
          },
          {
            title: 'Telefon Numarası',
            value: res.data.data.phone,
          },
          {
            title: 'Adres',
            value: res.data.data.adress,
          },
        ];
      });
      getCompanyFarmList(params).then((res) => {
        let totalNominalPlantPower = 0;
        let count = 0;
        Object.keys(res.data.data).forEach((i) => {
          totalNominalPlantPower += parseFloat(res.data.data[i].NominalPlantPower.value);
          count += 1;
        });
        this.totalNominalPlantPower.count = count;
        this.totalNominalPlantPower.value = totalNominalPlantPower;
        this.totalNominalPlantPower.unit = 'MWP';
      });

      getUserList(params).then((res) => {
        this.userCount = res.data.data.length;
      });
    },
    getCompanys() {
      const params = {
        condiniton: {
          companyCode: this.companyCode,
        },
      };

      getCompany(params).then((res) => {
        this.companyData = res.data.data;
        this.items = [
          {
            title: 'Şirket Adı',
            value: res.data.data.companyName,
          },

          {
            title: 'Oluşturma Tarih',
            value: res.data.data.createTime,
          },
          {
            title: 'Email',
            value: res.data.data.email,
          },
          {
            title: 'Telefon Numarası',
            value: res.data.data.phone,
          },
          {
            title: 'Adres',
            value: res.data.data.adress,
          },
        ];
      });
      getCompanyFarmList(params).then((res) => {
        let totalNominalPlantPower = 0;
        let count = 0;
        Object.keys(res.data.data).forEach((i) => {
          totalNominalPlantPower += parseFloat(res.data.data[i].NominalPlantPower.value);
          count += 1;
        });
        this.totalNominalPlantPower.count = count;
        this.totalNominalPlantPower.value = totalNominalPlantPower;
        this.totalNominalPlantPower.unit = 'MWP';
      });

      getUserList(params).then((res) => {
        this.userCount = res.data.data.length;
      });
    },
  },
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}

#map {
  height: 300px;
  width: 400px;
}
</style>
